import React, { FC, useEffect, useRef, useState } from 'react';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import classNames from 'classnames';

import { IUmbracoImageComponent } from './models';
import './UmbracoImage.scss';

const UmbracoImage: FC<IUmbracoImageComponent> = ({
  className,
  image,
  alt,
  loadingType = 'lazy',
}) => {
  const classes = classNames(className, 'umbraco-image');
  const imageRef = useRef<HTMLImageElement>(null);
  const [state, setState] = useState({
    mainImageOpacity: 0,
    base64ImageOpacity: 1,
  });
  const setImageReady = () => {
    setState({
      mainImageOpacity: 1,
      base64ImageOpacity: 0,
    });
  };

  if (!image) {
    return null;
  }

  useEffect(() => {
    if (imageRef.current?.complete) {
      setImageReady();
    }
  }, []);

  return (
    <div data-testid="umbraco-image" className={classes}>
      <picture>
        <source srcSet={image.fluid?.srcSet} type="image/webp" />
        <source srcSet={image.fallbackUrl} type="image/jpeg" />
        <img
          ref={imageRef}
          width="100%"
          height="100%"
          alt={alt}
          style={{
            transition: 'opacity 500ms ease 0s',
            opacity: state.mainImageOpacity,
          }}
          onLoad={setImageReady}
          sizes="(max-width: 800px) 100vw, 800px"
          srcSet={image.fluid?.srcSet}
          src={image.fallbackUrl}
          data-sizes="auto"
          loading={loadingType}
        />
      </picture>
    </div>
  );
};

export default UmbracoImage;
