import React, { FC } from 'react';
import { Link } from 'gatsby';

import { BreadCrumbsItemProps } from './models';

const BreadCrumbsItem: FC<BreadCrumbsItemProps> = ({ link, name, separator }) => (
  <li className="breadcrumb__item">
    <Link
      to={link}
      className="breadcrumb__link"
      activeClassName="breadcrumb__link--active"
      aria-current="page"
      aria-label={name}
    >
      {name}
    </Link>

    {separator ? (
      <span className="breadcrumb__separator" aria-hidden="true">
        {separator}
      </span>
    ) : null}
  </li>
);

export default BreadCrumbsItem;
