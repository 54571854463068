import { navigate } from 'gatsby';

export const navigateToSearchPage = (
  event: React.SyntheticEvent<HTMLElement>,
  searchQuery: string
): void => {
  event.preventDefault();
  if (searchQuery?.length) {
    navigate(`/search-results/?query=${searchQuery}`);
  } else {
    navigate(`/search-results`);
  }
};

export default navigateToSearchPage;
