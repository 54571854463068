import React, { FC, useState, useEffect } from 'react';

import { useLocation } from '@reach/router';

import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

import { isBrowser } from 'utils/browser';
import getQueryParamByName from 'utils/getQueryParamByName';
import classNames from 'classnames';
import { SearchBarProps } from './models';
import './SearchBar.scss';
import { navigateToSearchPage } from './utils';

const SearchBar: FC<SearchBarProps> = ({ className, onSearch }) => {
  const location = useLocation();
  const searchQuery = isBrowser() && getQueryParamByName('query');
  const [searchText, setSearchText] = useState(searchQuery || '');

  useEffect(() => {
    if (searchQuery && searchQuery !== searchText) {
      setSearchText(searchQuery);
    }
    if (!searchQuery && searchQuery !== searchText) {
      setSearchText('');
    }
  }, [location]);

  const onFormSubmit = (e) => {
    if (onSearch) {
      onSearch();
    }
    navigateToSearchPage(e, searchText);
  };

  return (
    <div className={classNames(className, 'search-bar')}>
      <Form inline onSubmit={onFormSubmit}>
        <InputGroup className="search-bar__input-group">
          <FormControl
            className="search-bar__input"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            id="inlineFormInputSearch"
            placeholder="Search"
          />
          <InputGroup.Append>
            <Button className="search-bar__button" type="submit" aria-label="Search">
              <span className="icon-search search-bar__icon" />
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </Form>
    </div>
  );
};

export default SearchBar;
