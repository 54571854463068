import React, { FC, useState, useCallback } from 'react';
import Navbar from 'react-bootstrap/Navbar';

import useBodyRef from 'hooks/useBodyRef';
import Logo from 'components/common/Logo';
import HeaderNav from 'components/Header/HeaderNav';
import SearchBar from 'components/common/SearchBar';

import { BODY_CLASS_NAME_MOBILE_MENU } from './constants';

import './Header.scss';

const Header: FC<HeaderProps> = () => {
  const bodyTag = useBodyRef();

  const [isMenuToggled, setMenuToggled] = useState(false);

  const handleToggle = useCallback(() => {
    if (bodyTag) {
      isMenuToggled
        ? bodyTag.classList.remove(BODY_CLASS_NAME_MOBILE_MENU)
        : bodyTag.classList.add(BODY_CLASS_NAME_MOBILE_MENU);
      setMenuToggled(!isMenuToggled);
    }
  }, [isMenuToggled, bodyTag]);

  const onSearchAction = () => {
    if (isMenuToggled) {
      setMenuToggled(false);
    }
  };

  return (
    <header className="header">
      <Navbar
        className="header__nav-wrapper"
        onToggle={handleToggle}
        expand="lg"
        expanded={isMenuToggled}
      >
        <div className="header__nav-holder">
          <Logo />
          <Navbar.Toggle className="header__nav-toggler" aria-controls="basic-navbar-nav" />
        </div>

        <Navbar.Collapse className="header__nav" id="basic-navbar-nav">
          <HeaderNav />
          <SearchBar className="header__searchbar" onSearch={onSearchAction} />
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

export default Header;
