import React, { FC } from 'react';

import { prepareDefaultPathLabel } from 'utils/breadcrumbs';
import BreadCrumbsItem from './BreadCrumbsItem';
import { BreadcrumbsProps } from './models';
import './BreadCrumbs.scss';

const BreadCrumbs: FC<BreadcrumbsProps> = ({
  data,
  separator,
  overWrittenPath,
  overWrittenLabel,
}) => {
  const prepareLabel = (pathUpdate, labelUpdated, itemPathname, itemLabel) => {
    if (labelUpdated && pathUpdate.slice(0, -1) === itemPathname) {
      return labelUpdated;
    }

    return prepareDefaultPathLabel(itemLabel);
  };

  return data ? (
    <div className="container-fluid">
      <ul className="breadcrumbs" data-testid="breadcrumbs">
        {data.map((item, index) => (
          <BreadCrumbsItem
            key={item.crumbLabel}
            link={item.pathname}
            name={prepareLabel(overWrittenPath, overWrittenLabel, item.pathname, item.crumbLabel)}
            separator={index === data.length - 1 ? null : separator}
          />
        ))}
      </ul>
    </div>
  ) : null;
};

export default BreadCrumbs;
