import React, { FC } from 'react';

import { NavDropdown } from 'react-bootstrap';
import { HeaderNavDropdownProps } from './models';
import './HeaderNavDropdown.scss';

const HeaderNavDropdown: FC<HeaderNavDropdownProps> = ({ dropdown: { label, url: urls } }) => (
  <NavDropdown
    id={`dropdown-${label}`}
    key={`HeaderDropdown-${label}`}
    className="header__dropdown"
    title={label}
  >
    {urls.map(({ url, name }) => (
      <NavDropdown.Item href={url} key={`HeaderDropdownItem-${name}`}>
        {name}
      </NavDropdown.Item>
    ))}
  </NavDropdown>
);

export default HeaderNavDropdown;
