import React, { FC, useEffect } from 'react';
import classNames from 'classnames';
import 'styles/main.scss';
import Seo from 'components/common/Seo';
import { isBrowser } from 'utils/browser';
import Header from 'components/Header';
import Footer from 'components/Footer/Footer';
import IconCustom from 'components/common/IconCustom';
import BreadCrumb from 'components/common/BreadCrumbs';
import { ILayout } from './models';

import './Layout.scss';

const Layout: FC<ILayout> = ({
  children,
  seo,
  headerTransparent,
  className,
  crumbsSettings: { crumbs, isDisplayedCrumbs = true, overWrittenPath, overWrittenLabel },
}) => {
  const classes = classNames('layout', className, {
    'layout--transparent': headerTransparent,
  });

  const handleSkipToContent = () => {
    const main = isBrowser() && document.getElementById('main');
    main && main.focus();
  };

  useEffect(() => {
    const gatsbyWrapper = isBrowser() && document.getElementById('gatsby-focus-wrapper');
    gatsbyWrapper && gatsbyWrapper.removeAttribute('tabindex');
  });

  return (
    <div data-testid="layout" className={classes}>
      {seo && (
        <Seo
          title={seo.seoMetaTitle}
          description={seo.seoMetaDescription}
          keywords={seo.seoMetaKeywords}
        />
      )}
      <link rel="preconnect" href={process.env.GATSBY_IMAGE_CLOUD_SERVER} />
      <a className="skip-main" href="#main" onClick={handleSkipToContent}>
        Skip to main content
      </a>
      <Header />

      {isDisplayedCrumbs ? (
        <BreadCrumb
          data={crumbs}
          separator={<IconCustom icon="next_arrow" />}
          overWrittenPath={overWrittenPath}
          overWrittenLabel={overWrittenLabel}
        />
      ) : null}

      <main id="main" className="layout__main">
        {children}
      </main>

      <Footer />
    </div>
  );
};

export default Layout;
