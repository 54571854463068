import React, { FC } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

import './Logo.scss';

import UmbracoImage from 'components/common/Image/UmbracoImage';

const Logo: FC = () => {
  const {
    brandSettings: { brandLogo, brandName },
  } = useStaticQuery(graphql`
    {
      brandSettings {
        brandName
        brandLogo {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
      }
    }
  `);

  return (
    <Link className="logo" to="/">
      <UmbracoImage image={brandLogo} alt={brandName} className="logo__img" />
    </Link>
  );
};

export default Logo;
